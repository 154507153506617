<template>
  <FormKit
      type="form"
      id="barbers"
      :actions="false"
      v-model="initValue"
      :classes="{messages: 'tw-text-red-500 tw-text-center tw-mt-4'}"
      @submit="submit"
  >
    <FormKit
        name="name"
        type="text"
        label="Nama Pegawai"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
    />
    <FormKit
        name="position"
        type="text"
        label="Posisi"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
    />
    <FormKit
        v-if="isCreate"
        name="nik"
        type="text"
        label="NIK"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
    />
    <FormKit
        name="status"
        type="select"
        label="Status"
        validation="required"
        placeholder="Pilih Status"
        :classes="defaultFormikListClass"
        :options="{'0': 'Non-Active', '1': 'Active'}"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown v-model="context.value" @change="(e) => {context.node.input(e.value)}" :options="context.options"
                    optionLabel="label" optionValue="value" :placeholder="context.placeholder">
            <template #option="optionContext">
              <option v-if="!optionContext.option.attrs" :key="optionContext.index" :value="optionContext.option.value">
                {{ optionContext.option.label }}
              </option>
              <option v-else :key="optionContext.index" :disabled="true">Not Selected</option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>
    <FormKit
        name="salon_id"
        type="select"
        label="Salon"
        validation="required"
        placeholder="Pilih Salon"
        :classes="defaultFormikListClass"
        :options="salons"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown v-model="context.value" @change="(e) => {context.node.input(e.value)}" :options="context.options"
                    optionLabel="label" optionValue="value" :placeholder="context.placeholder" :disabled="isAdminSalon()">
            <template #option="optionContext">
              <option v-if="!optionContext.option.attrs?.hidden" :key="optionContext.index"
                      :value="optionContext.option.value">{{ optionContext.option.label }}
              </option>
              <option v-else :key="optionContext.index" :disabled="true" :value="optionContext.option.value">Not
                Selected
              </option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>
    <FormKit
        name="gender"
        type="select"
        label="Jenis Kelamin"
        validation="required"
        placeholder="Pilih jenis kelamin"
        :classes="defaultFormikListClass"
        :options="{'M': 'Laki-Laki', 'F': 'Perempuan'}"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown v-model="context.value" @change="(e) => {context.node.input(e.value)}" :options="context.options"
                    optionLabel="label" optionValue="value" :placeholder="context.placeholder">
            <template #option="optionContext">
              <option v-if="!optionContext.option.attrs" :key="optionContext.index" :value="optionContext.option.value">
                {{ optionContext.option.label }}
              </option>
              <option v-else :key="optionContext.index" :disabled="true">Not Selected</option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>
    <FormKit
        name="hide_rating"
        type="checkbox"
        label="Sembunyikan Rating"
        :classes="{wrapper: 'tw-flex', label: 'tw-ml-4', outer: 'tw-my-4'}"
    />
  </FormKit>
  <div class="p-fluid tw-mt-12 tw-mb-4">
    <Button label="Submit" @click="submitData"/>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, onBeforeMount, ref, watch} from "vue";
import {submitForm} from "@formkit/vue";
import {defaultFormikListClass} from "@/constants/formik";
import {useSalonDropdown} from "@/composables/salon";
import {useAuthRole} from "@/composables/auth";

const props = defineProps({
  initialData: {
    type: Object,
    required: false,
  }
})
const initValue = ref(props.initialData)
const isCreate = ref(true)

const {isAdminSalon, salonId} = useAuthRole()
const {salons} = useSalonDropdown()
const emit = defineEmits(['submit'])

const submit = (data) => {
  const formData = {
    ...data,
    salon_id: parseInt(data.salon_id),
    status: parseInt(data.status),
    hide_rating: data.hide_rating || false,
  }
  emit('submit', formData)
}

onBeforeMount(() => {
  if (isAdminSalon()) {
    initValue.value = {
      'salon_id': String(salonId.value)
    }
  }
})

watch(() => props.initialData, (newData) => {
  initValue.value = newData
  isCreate.value = false
})

const submitData = () => {
  submitForm('barbers')
}
</script>
