<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Create Employee</h1>
    <div>
      <BarberForm @submit="createBarber" />
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import BarberForm from "./Form";
import {useBarberForm} from "@/composables/barber";

const {create, submitNIK} = useBarberForm()
const router = useRouter();

const createBarber = async (data) => {
  const res = await create(data);
  if (data.nik) await submitNIK(res.data.id, data.nik)
  await router.push({name: 'barbers'})
}
</script>

<style scoped>

</style>